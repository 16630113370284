import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isHovered: false,
  isHoveredId: null,
  isHoveredImg: null,
  isHoveredText: null,
  activeImg: false,
  currentImgNum: null,
  currentImg: null,
  currentImgText: null,
};

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState,
  reducers: {
    setIsHovered: (state, action) => {
      state.isHovered = action.payload;
    },
    setIsHoveredId: (state, action) => {
      state.isHoveredId = action.payload;
    },
    setIsHoveredImg: (state, action) => {
      state.isHoveredImg = action.payload;
    },
    setIsHoveredText: (state, action) => {
      state.isHoveredText = action.payload;
    },
    setActiveImg: (state, action) => {
      state.activeImg = action.payload;
    },
    setcurrentImgNum: (state, action) => {
      state.currentImgNum = action.payload;
    },
    setcurrentImg: (state, action) => {
      state.currentImg = action.payload;
    },
    setcurrentImgText: (state, action) => {
      state.currentImgText = action.payload;
    },
  },
});

export default utilitiesSlice.reducer;
export const {
  setIsHovered,
  setIsHoveredId,
  setIsHoveredImg,
  setIsHoveredText,
  setActiveImg,
  setcurrentImgNum,
  setcurrentImg,
  setcurrentImgText,
} = utilitiesSlice.actions;
